import React from "react";

import { CurrencyDisplay, DateDisplay, Grid, Space, Status, Text } from "components";
import { getDeliveryStatus } from "pages/Payments";
import { CombinedPayment } from "store/hooks/payments";
import css, { createUseStyle } from "style/classname";
import { IntlMessageKeys, useIntl } from "utils/context";

export default function PaymentHeader(props: {
  payment: CombinedPayment;
  activeKey: string;
  status: ReturnType<typeof getDeliveryStatus>;
}) {
  const { payment, activeKey, status: deliveryStatus } = props;
  const intl = useIntl();
  const styledHeaderDate = useStyledHeaderDate({
    hidden: activeKey === payment.id,
  });

  return (
    <Grid alignItems="middle" padding="none" style={{ padding: "14px 0 14px 8px" }}>
      <Grid.Item align="left" flex={1}>
        <Grid padding="none" justify="start" alignItems="middle" wrap={false}>
          <Grid.Item flex={1}>
            <Space>
              <>
                {payment.targetAmount ? (
                  <>
                    <Text strong inline>
                      <CurrencyDisplay value={payment.amount} currency={payment.currency} />
                    </Text>
                  </>
                ) : (
                  <Text strong>
                    <CurrencyDisplay value={payment.amount} currency={payment.currency} />
                  </Text>
                )}
              </>
              <>
                {deliveryStatus ? (
                  deliveryStatus === "delivering" ? (
                    <Status type="info">{intl.formatMessage({ id: "containers.payments.enRoute" })}</Status>
                  ) : (
                    <Status type="success">{intl.formatMessage({ id: "containers.payments.delivered" })}</Status>
                  )
                ) : (
                  <Status type={payment.status === "returned" ? "critical" : "success"}>
                    {intl.formatMessage({
                      id: `containers.payments.${payment.status}` as IntlMessageKeys, // likely RETURNED, since we don't show pending, processing, nor failed payments
                    })}
                  </Status>
                )}
              </>
            </Space>
            <Text type="secondary" size="small" className={styledHeaderDate}>
              {intl.formatMessage({ id: "containers.payments.dateSent" })}{" "}
              <DateDisplay value={payment.processedAt} time={false} />
            </Text>
          </Grid.Item>
        </Grid>
      </Grid.Item>
    </Grid>
  );
}

const useStyledHeaderDate = createUseStyle<{ hidden: boolean }>(
  css`
    transition: height 0.3s ease, opacity 0.2s ease;
    height: ${(props) => (props.hidden ? "0px" : "18px")};
    opacity: ${(props) => (props.hidden ? "0" : "1")};
  `,
);
