import { RecipientType } from "@trolley/common-frontend";
import { Divider, Grid, Icon, Space, Typography } from "components";
import dayjs from "dayjs";
import React from "react";
import { useIntl } from "utils/context";
import { useWindowSize } from "utils/hooks";

interface Props {
  recipientType: RecipientType;
  submittedAt?: string | null;
  actionButtons?: React.ReactNode;
  statusElement?: React.ReactNode;
}

export default function BasicCardLayout({ recipientType, submittedAt, statusElement, actionButtons }: Props) {
  const { formatMessage } = useIntl();
  const { isMobile } = useWindowSize();

  return (
    <>
      <Grid justify="space-between" alignItems="middle" wrap={false} padding="none">
        <Grid.Item xs={22}>
          <Grid direction={isMobile ? "column" : "row"} padding={isMobile ? "small" : "medium"} wrap={false}>
            <Grid.Item align="center" style={{ minWidth: "50px" }} xs={1}>
              <Icon
                type={recipientType === RecipientType.INDIVIDUAL ? "id-card" : "briefcase"}
                size="xlarge"
                left={false}
                fixedWidth={false}
                theme="light"
                style={{ ...(!isMobile ? { paddingLeft: 9 } : {}), ...(submittedAt ? { paddingTop: 5 } : {}) }}
              />
            </Grid.Item>
            <Grid.Item xs={statusElement ? 8 : 10} style={isMobile ? { width: "100%", maxWidth: "100%" } : {}}>
              <Space direction="column" align="start" size="none">
                <Typography.Text strong inline>
                  {recipientType === RecipientType.INDIVIDUAL
                    ? formatMessage({ id: "containers.trust.individualVerification" })
                    : formatMessage({ id: "containers.trust.businessVerification" })}
                </Typography.Text>
                {submittedAt && (
                  <Typography.Text type="secondary">
                    {formatMessage(
                      {
                        id: "common.submittedOn",
                      },
                      { date: dayjs(submittedAt).format("ll") },
                    )}
                  </Typography.Text>
                )}
              </Space>
            </Grid.Item>
            {statusElement && !isMobile && (
              <Grid.Item xs={14} style={{ ...(submittedAt && !isMobile ? { paddingTop: 15 } : {}) }}>
                {statusElement}
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
        {actionButtons && (
          <Grid.Item align="right" style={{ marginTop: 0, marginBottom: "auto" }}>
            {actionButtons}
          </Grid.Item>
        )}
      </Grid>
      {statusElement && isMobile && (
        <>
          <Divider transparent size="small" />
          {statusElement}
        </>
      )}
    </>
  );
}
