// only get constant equivalent accessible color value to be used on portal, not on widget

const AccessibleColors = {
  info: "#A3CEFF",
  success: "#C5DBCC",
  warning: "#FFDFA3",
  critical: "#FFE6E6",
  default: "#B5B5B5",
};

const AccessibleTextColors = {
  info: "#000302",
  success: "#000302",
  warning: "#612500",
  critical: "#61001A",
  default: "#000302",
};

export default function getAccessibleEqColor(type: "info" | "success" | "warning" | "critical" | "default") {
  return AccessibleColors[type];
}

export function getAccessibleTextColor(type: "info" | "success" | "warning" | "critical" | "default") {
  return AccessibleTextColors[type];
}
