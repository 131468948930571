import React, { useMemo } from "react";
import { VerificationRejectedReasons, VerificationStatus } from "@trolley/common-frontend";
import { Tag } from "antd";
import { Icon, Typography } from "components";
import { useIntl } from "utils/context";
import { useWindowSize } from "utils/hooks";
import { getAccessibleEqColor, getAccessibleTextColor } from "utils/helpers";

interface Props {
  status: VerificationStatus;
  rejectedReason?: VerificationRejectedReasons;
}

export default function IdvStatusDisplay({ status, rejectedReason = VerificationRejectedReasons.OTHER }: Props) {
  const { formatMessage } = useIntl();
  const { isMobile } = useWindowSize();

  const TagInfo = useMemo(
    () => ({
      [VerificationStatus.APPROVED]: {
        icon: <Icon type="check" style={{ color: getAccessibleTextColor("success") }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.approved" }),
        // color: "success",
        color: getAccessibleEqColor("success"),
        textColor: getAccessibleTextColor("success"),
      },
      [VerificationStatus.PENDING]: {
        icon: <Icon type="clock" style={{ color: getAccessibleTextColor("info") }} size="large" />,
        label: formatMessage({
          id: "containers.trust.verificationStatus.pending",
        }),
        // color: "processing",
        color: getAccessibleEqColor("info"),
        textColor: getAccessibleTextColor("info"),
      },
      [VerificationStatus.REJECTED]: {
        icon: (
          <Icon
            type="circle-exclamation"
            style={{
              color:
                rejectedReason === VerificationRejectedReasons.FRAUDULENT
                  ? getAccessibleTextColor("critical")
                  : getAccessibleTextColor("warning"),
            }}
            size="large"
          />
        ),
        label: `${formatMessage({ id: "containers.trust.verificationStatus.rejected" })} - ${formatMessage({
          id: `containers.trust.rejectedReasons.${rejectedReason}`,
        })}`,
        // color: "error",
        color:
          rejectedReason === VerificationRejectedReasons.FRAUDULENT
            ? getAccessibleEqColor("critical")
            : getAccessibleEqColor("warning"),
        textColor:
          rejectedReason === VerificationRejectedReasons.FRAUDULENT
            ? getAccessibleTextColor("critical")
            : getAccessibleTextColor("warning"),
      },
      [VerificationStatus.RETRY]: {
        icon: <Icon type="clock" style={{ color: getAccessibleTextColor("warning") }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.retry" }),
        // color: "warning",
        color: getAccessibleEqColor("warning"),
        textColor: getAccessibleTextColor("warning"),
      },
      [VerificationStatus.REVIEW]: {
        icon: <Icon type="clock" style={{ color: getAccessibleTextColor("info") }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.review" }),
        // color: "processing",
        color: getAccessibleEqColor("info"),
        textColor: getAccessibleTextColor("info"),
      },
      [VerificationStatus.SUBMITTED]: {
        icon: <Icon type="clock" style={{ color: getAccessibleTextColor("info") }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.submitted" }),
        // color: "processing",
        color: getAccessibleEqColor("info"),
        textColor: getAccessibleTextColor("info"),
      },
      [VerificationStatus.EXPIRED]: {
        icon: <Icon type="check" style={{ color: getAccessibleTextColor("default") }} />,
        label: formatMessage({ id: "containers.trust.verificationStatus.expired" }),
        // color: "default",
        color: getAccessibleEqColor("default"),
        textColor: getAccessibleTextColor("default"),
      },
    }),
    [formatMessage],
  );

  const { icon, label, color, textColor } = TagInfo[status];

  return (
    <>
      <Tag icon={icon} color={color} style={{ borderRadius: 4, whiteSpace: "break-spaces", wordWrap: "break-word" }}>
        <Typography.Text
          size={isMobile ? "base" : "large"}
          uppercase
          inline
          style={{ marginLeft: "5px", color: textColor }}
        >
          {label}
        </Typography.Text>
      </Tag>
    </>
  );
}
