import BigNumber from "bignumber.js";
import {
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  LinkButton,
  List,
  Loader,
  Space,
  Status,
  Text,
  TitleBar,
  Tooltip,
} from "components";
import { IntlMessageKeys, useIntl, PRODUCT_MODULES, useProducts } from "utils/context";
import { PATHS } from "pages/App/routes";
import { getPaymentCategoryLabels } from "data/withholding/variables";
import dayjs from "dayjs";
import React, { useState } from "react";
import { getTaxFormLabel, getWithholdingReasonLabel, TaxForm, UsUpload } from "store/actions/taxForms";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useTaxForms } from "store/hooks/taxForms";
import { BaseStatus } from "store/reducers/standardReducer";
import { PaymentCategory, TaxDeliveryType, TaxFormType, TaxStatus } from "@trolley/common-frontend";
import TaxFormDelivery from "../TaxFormDelivery";
import { showProduct } from "utils/helpers";

export default function TaxForms() {
  const { data: taxForms, status: taxFormsStatus } = useTaxForms();
  const config = useIframeConfig();
  const recipient = useRecipient();
  const merchant = useMerchant();

  const { formatMessage } = useIntl();
  const [showTaxDelivery, setShowTaxDelivery] = useState(false);
  const { queryProducts, entitledProducts } = useProducts();
  const showPayProduct = showProduct(PRODUCT_MODULES.PAY, queryProducts, entitledProducts);

  const visibleTaxForms = taxForms.records.filter(
    // if tax form upload is disabled, hide incomplete US:UPLOAD form to being edited.
    (tf) => tf.status !== TaxStatus.INCOMPLETE || tf.kind !== TaxFormType.US_UPLOAD || config.taxFormUpload,
  );

  if (!merchant || !recipient) {
    return null;
  }

  const canAddNewTax =
    !showPayProduct || !config.showPayoutMethods || !!(recipient.name && recipient.accounts.length > 0); // if payout methods are shown/editable, recipient needs to add one before adding a tax form

  function renderWithholding(form: TaxForm) {
    if (form.kind === TaxFormType.W9) {
      return (
        <Text size="small">
          {form.withholding}%
          {new BigNumber(form.withholding ?? 0).gt(0) && (
            <Tooltip
              placement="topRight"
              title={
                <div>
                  <Text>{formatMessage({ id: "containers.tax.reasons" })}</Text>
                  <ul>
                    {form.withholdingReasons.map((reason: string) => (
                      <li key={`reason_${reason}`}>{getWithholdingReasonLabel(reason)}</li>
                    ))}
                  </ul>
                </div>
              }
            >
              <Icon type="info-circle" theme="solid" right />
            </Tooltip>
          )}
        </Text>
      );
    } else if ([TaxFormType.W8BEN, TaxFormType.W8BENE].includes(form.kind as TaxFormType)) {
      const withholdings = form.calculatedWithholdings;
      const paymentCategoryLabels = getPaymentCategoryLabels(formatMessage);
      const defaultCategory = merchant?.payment?.defaultCategory;
      const enabledCategories =
        merchant?.payment &&
        (Object.keys(merchant.payment?.categories || {}).filter(
          (key) => !!merchant.payment?.categories?.[key] && paymentCategoryLabels[key]?.incomeCode,
        ) as PaymentCategory[]);
      const otherEnabledCategories = enabledCategories?.filter((cat) => cat !== defaultCategory);

      const defaultCategoryLabels = defaultCategory && paymentCategoryLabels[defaultCategory];
      const defaultWithholding = defaultCategoryLabels?.incomeCode && withholdings[defaultCategoryLabels.incomeCode];

      return defaultWithholding ? (
        <Text size="small">
          {defaultCategoryLabels?.name}: <strong>{defaultWithholding.withholdingPercentage}%</strong>
          {!!enabledCategories?.length && (
            <Tooltip
              placement="topRight"
              title={
                <div>
                  <Text padded>
                    {defaultCategoryLabels?.name}:{" "}
                    <Text strong inline>
                      {defaultWithholding.withholdingPercentage}%
                    </Text>
                  </Text>
                  <Grid justify="start" alignItems="top" padding="none" wrap={false}>
                    <Grid.Item style={{ minWidth: "19px" }}>
                      <Icon type="question-circle" theme="solid" size="small" />
                    </Grid.Item>
                    <Grid.Item>{getWithholdingReasonLabel(defaultWithholding.reason[0])}</Grid.Item>
                  </Grid>
                  {!!otherEnabledCategories?.length && (
                    <>
                      <Divider />
                      <ul>
                        {otherEnabledCategories.map((cat) => (
                          <li key={cat}>
                            {paymentCategoryLabels[cat].name}:{" "}
                            <strong>
                              {new BigNumber(
                                withholdings[paymentCategoryLabels[cat].incomeCode || ""]?.withholdingPercentage || "0",
                              ).toFixed(1)}
                              %
                            </strong>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              }
            >
              <Icon type="info-circle" theme="solid" right />
            </Tooltip>
          )}
        </Text>
      ) : (
        <Text strong>{form.withholding}%</Text>
      );
    }

    return null;
  }

  function renderTaxForm(item: TaxForm) {
    const data = item.data as UsUpload;

    const withholding = renderWithholding(item);
    const voidReason = item.status === TaxStatus.VOIDED && item.voidReason;

    return (
      <Grid
        justify="space-between"
        alignItems="middle"
        wrap={false}
        padding="none"
        style={{ padding: "14px 0 14px 8px" }}
      >
        <Grid.Item>
          <Space size="small">
            <Icon size="xlarge" theme="light" type="file-alt" />
            <Space direction="column" align="start" size="none">
              <Text strong>
                {getTaxFormLabel(data.uploadKind || item.kind)}
                {item.kind === TaxFormType.US_UPLOAD && data.uploadKind && ` (Uploaded)`}
                <Status
                  type={
                    {
                      [TaxStatus.SUBMITTED]: "info" as "info",
                      [TaxStatus.INCOMPLETE]: "warning" as "warning",
                      [TaxStatus.REVIEWED]: "success" as "success",
                    }[item.status] || "default"
                  }
                  suffix={voidReason && <Icon right type="question-circle" />}
                  tooltip={
                    voidReason && (
                      <>
                        <Text strong>
                          {formatMessage({
                            id: `containers.accountSummary.voidedDueTo`,
                          })}
                        </Text>
                        {formatMessage({
                          id: `containers.accountSummary.taxVoidReason.${voidReason}`,
                          defaultMessage: voidReason,
                        })}
                      </>
                    )
                  }
                >
                  {formatMessage({
                    id: `containers.accountSummary.taxStatuses.${item.status}` as IntlMessageKeys,
                    defaultMessage: item.status,
                  })}
                </Status>
              </Text>
              <Text type="secondary" size="small">
                {item.signedAt
                  ? formatMessage(
                      {
                        id: "common.submittedOn",
                      },
                      { date: dayjs(item.signedAt).format("ll") },
                    )
                  : formatMessage({ id: "containers.accountSummary.notSubmitted" })}
              </Text>
            </Space>
          </Space>
        </Grid.Item>
        <Grid.Item align="right">
          {item.signedAt && withholding && (
            <>
              <Text type="label">
                {formatMessage({
                  id: "containers.accountSummary.headers.withholdingRate",
                })}
              </Text>
              {withholding}
            </>
          )}
          {item.status === "incomplete" && (
            <Tooltip title={<Text size="small">{formatMessage({ id: "common.edit" })}</Text>}>
              <LinkButton
                shape="circle"
                block
                placement="right"
                icon="pencil"
                to={{
                  pathname: `${item.kind === TaxFormType.US_UPLOAD ? PATHS.US_TAX_UPLOAD : PATHS.US_TAX}/${item.id}`,
                  state: item,
                }}
                aria-label={formatMessage({ id: "common.edit" })}
              />
            </Tooltip>
          )}
        </Grid.Item>
      </Grid>
    );
  }

  if (visibleTaxForms.length === 0) {
    return (
      <Loader spinning={taxFormsStatus === BaseStatus.LOADING}>
        <div style={{ textAlign: "center", margin: "60px 0" }}>
          <Text padded>
            <Icon size="xlarge" theme="light" type="file-alt" />
          </Text>
          <Text strong padded>
            {formatMessage({ id: "containers.accountSummary.noTaxFormSetup.1" })}
          </Text>
          {canAddNewTax ? (
            <>
              <Text padded>
                {formatMessage({
                  id: !showPayProduct
                    ? "containers.accountSummary.noTaxFormSetup.3"
                    : "containers.accountSummary.noTaxFormSetup.2",
                })}
              </Text>
              <Divider transparent />

              <LinkButton icon="plus" size="large" type="primary" to={PATHS.US_TAX}>
                {formatMessage({
                  id: "containers.accountSummary.buttons.completeNewTaxForm",
                })}
              </LinkButton>
            </>
          ) : (
            <Text padded>
              {formatMessage({
                id: "containers.accountSummary.noTaxFormSetup.noPayout",
              })}
            </Text>
          )}
        </div>
      </Loader>
    );
  }

  // TOOLTIP Fix: the tooltip would still show over the modal that pops up, need to manually set it to hidden but can't pass in undefined.
  const visibleProp: { visible?: boolean } = {};
  if (showTaxDelivery) {
    visibleProp.visible = false;
  }

  return (
    <Loader spinning={taxFormsStatus === BaseStatus.LOADING}>
      <Container>
        <TitleBar
          level={2}
          extraActions={
            <Text strong inline type="secondary" capitalize>
              {formatMessage({
                id: "containers.accountSummary.headers.formDelivery",
              })}
              :{" "}
              {recipient.taxDeliveryType === "mail" ? (
                <Button
                  onClick={() => {
                    setShowTaxDelivery(true);
                  }}
                  type="link"
                >
                  {formatMessage({
                    id: "containers.tax.taxFormDelivery.postalMail",
                  })}
                </Button>
              ) : (
                recipient.taxDeliveryType
              )}
              <Tooltip
                placement="topRight"
                title={
                  <>
                    {recipient.taxDeliveryType
                      ? formatMessage(
                          {
                            id: "containers.tax.taxFormDelivery.willBeDelivered",
                          },
                          {
                            method: (
                              <b>
                                {formatMessage({
                                  id:
                                    recipient.taxDeliveryType === TaxDeliveryType.MAIL
                                      ? "containers.tax.taxFormDelivery.postalMail"
                                      : "containers.tax.taxFormDelivery.eDelivery",
                                })}
                              </b>
                            ),
                          },
                        )
                      : formatMessage({
                          id: "containers.tax.taxFormDelivery.notSet",
                        })}
                    <Button
                      type="link"
                      onClick={() => {
                        setShowTaxDelivery(true);
                      }}
                    >
                      {formatMessage({
                        id: "containers.tax.taxFormDelivery.change",
                      })}
                    </Button>
                  </>
                }
                {...visibleProp}
              >
                <Icon theme="solid" right type="question-circle" />
              </Tooltip>
            </Text>
          }
        >
          {formatMessage({
            id: "containers.accountSummary.titles.taxForms",
          })}
        </TitleBar>

        {merchant?.tax?.enabled && (
          <>
            <List<TaxForm>
              items={visibleTaxForms}
              renderItem={renderTaxForm}
              defaultRecordCount={1}
              showMoreText={formatMessage({
                id: "containers.accountSummary.buttons.seeMoreTaxForms",
              })}
              showLessText={formatMessage({
                id: "containers.accountSummary.buttons.seeLessTaxForms",
              })}
            />
            <Divider transparent size="small" />
            <LinkButton icon="plus" type="primary" to={PATHS.US_TAX} placement="right">
              {visibleTaxForms.length > 0
                ? formatMessage({
                    id: "containers.accountSummary.buttons.completeNewTaxForm",
                  })
                : formatMessage({
                    id: "containers.accountSummary.buttons.completeTaxForm",
                  })}
            </LinkButton>
          </>
        )}
        {showTaxDelivery && (
          <TaxFormDelivery
            onComplete={() => {
              setShowTaxDelivery(false);
            }}
            onExit={() => {
              setShowTaxDelivery(false);
            }}
            submitText={formatMessage({
              id: "common.save",
              defaultMessage: "Save",
            })}
            popup
          />
        )}
      </Container>
    </Loader>
  );
}
